@import "styles/mixins";

.tag {
    color:var(--clr-neutral-950);
    background:#eedfec;
    width: fit-content;
    padding:4px 8px;
    font-size:0.75rem;
    font-weight: 500;
    display:flex;
    align-items: center;
    gap:3px;
    border-radius:50px;
    &:hover {
      background-color: var(--clr-accent-300);
      color: var(--clr-neutral-100);
      svg {
        color: var(--clr-neutral-100);
      }
    }

    svg {
      font-size:16px;
      color:var(--clr-accent-300);
    }
  }
